import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'

import Layout, { PreFooter } from '../components/Layout'
import SEO from '../components/seo'

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const post = this.props.data.mdx
    const { frontmatter } = post
    const siteTitle = this.props.data.site.siteMetadata.title

    const { pageContext } = this.props
    const { previous } = pageContext

    let preFooterPost = previous
    if (!preFooterPost) {
      preFooterPost = this.props.data.allMdx.edges[0].node
    }

    const shareImage = frontmatter.shareImage || frontmatter.featuredImage

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={frontmatter.title}
          image={shareImage ? `https://knocking.com${shareImage}` : null}
          description={frontmatter.description}
        />

        <div className="post-single">
          <div className="post-single-heading">
            <div className="container">
              <div className="columns post-single ui-grid">
                <div className="column is-8">
                  <p className="cp-date">{frontmatter.date}</p>

                  <h1 className="primary heading_lg">{frontmatter.title}</h1>

                  {frontmatter.description && (
                    <p className="cp-desc">{frontmatter.description}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {frontmatter.featuredImage && (
            <div className="post-featured-image">
              <div className="container aligncenter">
                <div className="columns post-single ui-grid">
                  <div className="column is-10">
                    <img src={frontmatter.featuredImage} />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="container">
            <div className="post-body content">
              <div className="columns post-single ui-grid">
                <div className="column is-8">
                  <MDXProvider>
                    <MDXRenderer>{post.body}</MDXRenderer>
                  </MDXProvider>
                </div>
              </div>
            </div>
          </div>

          <div className="container aligncenter">
            <div className="columns post-single ui-grid">
              <div className="column is-8">
                <div className="cp-divider"></div>
              </div>
            </div>
          </div>

          {preFooterPost && (
            <PreFooter
              styles={{
                paddingTop: '7em',
              }}
              preTitle="Next up"
              title={preFooterPost.frontmatter.title}
			  titleClickable={true}
              linkText="View article"
              linkTo={preFooterPost.fields.slug}
            />
          )}
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage
      }
      body
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "press" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            posttype
          }
        }
      }
    }
  }
`
